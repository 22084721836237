import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import { graphql } from 'gatsby'
import Copy from 'components/category/copy'
import Content from 'components/category/content'

const items = [
  {
    link: '/stihl/batteries-and-accessories/',
    imageId: 'batteriesImage',
    name: 'Batteries & Accessories',
    alt: 'STIHL Batteries',
  },
  {
    link: '/stihl/blowers-and-shredder-vacs/battery-blowers/',
    imageId: 'batteryBlowersImage',
    name: 'Battery Blowers',
    alt: 'STIHL Battery Blower',
  },
  {
    link: '/stihl/chain-saws/battery-saws/',
    imageId: 'batterySawsImage',
    name: 'Battery Saws',
    alt: 'STIHL Battery Saws',
  },
  {
    link: '/stihl/lawn-mowers/homeowner-lawn-mowers/',
    imageId: 'batteryLawnMowersImage',
    name: 'Battery Lawn Mowers',
    alt: 'STIHL Battery Lawn Mowers',
  },
  {
    link: '/stihl/trimmers-and-brushcutters/battery-trimmers/',
    imageId: 'batteryTrimmersImage',
    name: 'Battery Trimmers',
    alt: 'STIHL Battery Trimmers',
  },
  {
    link: '/stihl/hedge-trimmers/battery-hedge-trimmers/',
    imageId: 'batteryHedgeTrimmersImage',
    name: 'Battery Hedge Trimmers',
    alt: 'STIHL Battery Hedge Trimmers',
  },
  {
    link: '/stihl/pole-pruners/battery-pole-pruners/',
    imageId: 'batteryPolePrunersImage',
    name: 'Battery Pole Pruners',
    alt: 'STIHL Battery Pole Pruners',
  },
  {
    link: '/stihl/cut-off-machines/battery-cut-off-machines/',
    imageId: 'batteryCutOffMachineImage',
    name: 'Battery Cut-Off Machines',
    alt: 'STIHL Battery Cut-Off Machines',
  },
]

const BatteryPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Battery | Hutson Inc</title>
        <meta
          name='description'
          content="The STIHL Battery product line packs a punch, getting the job done as well as gas-powered products. Whether you're an occasional user or a professional, we've got a STIHL battery product for you."
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Battery',
                'item': 'https://www.hutsoninc.com/stihl/battery/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Battery' />

      <Content>
        <Floater items={floaterItems} />

        <Copy header='STIHL Battery'>
          <p>
            The STIHL Lightning Battery System® is available in three tiers of performance,
            including occasional use, frequent and extensive. Whether you’re a landscaping
            professional or weekend warrior, STIHL Lightning strikes the perfect balance of
            performance, features and value, giving you the power to finish the job on a single
            charge.
          </p>
        </Copy>

        <Promos data={promos} type='STIHL Battery' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query batteryStihlQuery($category: String = "battery") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/battery-bg.jpg" }) {
      ...FullWidthImage
    }
    batteriesImage: file(relativePath: { eq: "stihl/batteries-and-accessories/batteries.jpg" }) {
      ...FloatingGridImage
    }
    batteryBlowersImage: file(
      relativePath: { eq: "stihl/blowers-and-shredder-vacs/battery-blowers.jpg" }
    ) {
      ...FloatingGridImage
    }
    batterySawsImage: file(relativePath: { eq: "stihl/chain-saws/battery-saws.jpg" }) {
      ...FloatingGridImage
    }
    batteryLawnMowersImage: file(
      relativePath: { eq: "stihl/lawn-mowers/homeowner-lawn-mowers.jpg" }
    ) {
      ...FloatingGridImage
    }
    batteryTrimmersImage: file(
      relativePath: { eq: "stihl/trimmers-and-brushcutters/battery-trimmers.jpg" }
    ) {
      ...FloatingGridImage
    }
    batteryHedgeTrimmersImage: file(
      relativePath: { eq: "stihl/hedge-trimmers/battery-hedge-trimmers.jpg" }
    ) {
      ...FloatingGridImage
    }
    batteryPolePrunersImage: file(
      relativePath: { eq: "stihl/pole-pruners/battery-pole-pruners.jpg" }
    ) {
      ...FloatingGridImage
    }
    batteryCutOffMachineImage: file(
      relativePath: { eq: "stihl/cut-off-machines/battery-cut-off-machine.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default BatteryPage
